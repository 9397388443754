import { Box, Button, Grid, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import { NavLink, Outlet } from "react-router-dom";
import logo from "../../src/public/logoWhite.svg";
import { config } from "../config";
import { logout } from "../lib/api/Auth";
import { useUserInfoContext } from "../lib/contexts/UserInfoContext";

export default function Header() {
  const { userInfo, updateUserInfo } = useUserInfoContext();

  const handleLogout = () => {
    logout();
    updateUserInfo(null);
  };

  return (
    <>
      <Box>
        <AppBar
          position="static"
          sx={{
            background: "rgba(0, 0, 0, 0.87)",
            color: "white",
            height: "7vh",
          }}
        >
          <Grid container sx={{ height: "7vh" }}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                px: "1vh",
              }}
            >
              <NavLink
                to={``}
                style={{
                  textDecoration: "none",
                  color: "white",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* Replace with app logo */}
                  <img
                    src={logo}
                    alt="slalom"
                    style={{
                      width: "60px",
                      height: "20px",
                      marginBottom: "5px",
                    }}
                  />
                  <Typography
                    sx={{
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                    variant="h6"
                  >
                    |
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "14.75px",
                    }}
                    variant="h6"
                  >
                    {config.styledText}
                  </Typography>
                </Box>
              </NavLink>
              <Divider />
              <Divider />
              {userInfo != null && (
                <Button variant="contained" onClick={handleLogout}>
                  Logout
                </Button>
              )}
            </Box>
          </Grid>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
}
