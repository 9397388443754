/************************************************************************************
  Customize the application by changing the values in this file
*************************************************************************************/

export const config = {
  styledText: "G P T 4 M E",
  applicationName: "GPT4ME",
  companyName: "Slalom",
  applicationSummary: 
    "Slalom's exclusive AI tool, designed to provide answers to a diverse" +
    "range of inquiries about Slalom and its work. Feel free to begin by " +
    "asking a question or choosing from the sample questions provided below.",
}

export const exampleQuestions = [
  "What is Slalom?",
  "What services does Slalom provide?",
  "Has Slalom done work with AWS?",
  "Who is Slalom's CEO?",
]
